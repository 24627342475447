<template>
  <silent-box class="thumb" :gallery="gallery"></silent-box>
</template>
<style>
.thumb {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  align-items: center;
}
@media only screen and (max-width: 1500px) {
  .thumb {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1000px) {
  .thumb {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .thumb {
    grid-template-columns: repeat(1, 1fr);
  }
}
.thumb .silentbox-item {
  display: inline-grid;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-height: 150px;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}
.thumb .silentbox-item img {
  width: 100%;
}
.thumb img {
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(99, 68, 68, 0.3);
}
</style>
<script>
export default {
  components: {},
  props: ["gallery"],
  data() {
    return {
      index: null,
    };
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>