<template>
  <div>
    <v-card flat>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="text-center" style="width: 100vw">
          <span style="width: 30px; float: left">
            <router-link to="/"> <v-icon>mdi-arrow-left</v-icon> </router-link>
          </span>
          <span> Wedding </span>
        </v-toolbar-title>
        <template v-slot:extension>
          <v-tabs v-model="tabs" centered>
            <v-tab>Programme</v-tab>
            <v-tab>Videos</v-tab>
            <v-tab>Photos</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-container>
            <calendar :date="date"></calendar>
            <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
              <v-timeline-item :icon="'mdi-bell'" fill-dot>
                <template v-slot:opposite>
                  <span
                    :class="`headline  success--text`"
                    v-text="'6AM - 7:30AM'"
                  ></span>
                </template>
                <div class="py-4">
                  <h2
                    :class="`headline font-weight-light mb-4 secondary--text`"
                  >
                    Muhurtham<br />
                    <span class="bold--text success--text d-md-none"
                      >6AM - 7:30AM</span
                    >
                    <v-btn
                      max-width="100%"
                      truncate
                      @click="openGoogleCalendar"
                      color="secondary"
                    >
                      Add to Google Calendar
                    </v-btn>
                  </h2>
                  <div>
                    <v-img
                      src="../../public/img/photos/assets/kumarakovil.jpg"
                      cover
                      class="grey darken-4 timeline-image"
                    ></v-img>
                    <h4 class="text-center primary--text">
                      Velimalai Sri Kumaraswamy Temple
                    </h4>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.592344408944!2d77.34772281521403!3d8.243681603061946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f84bfe2793b3%3A0x903df9a1ba52d678!2sVelimalai%20Sri%20Kumaraswamy%20Temple!5e0!3m2!1sen!2sin!4v1612280505233!5m2!1sen!2sin"
                      width="100%"
                      height="300px"
                      frameborder="0"
                      style="border: 0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </div>
              </v-timeline-item>
              <v-timeline-item :icon="'mdi-food'" fill-dot>
                <template v-slot:opposite>
                  <span
                    :class="`headline  success--text`"
                    v-text="'11AM - 2PM'"
                  ></span>
                </template>
                <div class="py-4">
                  <h2
                    :class="`headline font-weight-light mb-4 secondary--text`"
                  >
                    Reception<br />
                    <span class="bold--text success--text d-md-none"
                      >11AM - 2PM</span
                    >
                  </h2>
                  <v-img
                    src="../../public/img/photos/assets/vijedha.jpg"
                    cover
                    class="grey darken-4 timeline-image"
                  ></v-img>
                  <h4 class="text-center primary--text">Hotel Vijayetha</h4>
                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.16161234235!2d77.42277131521394!3d8.18646970386742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f0d800483495%3A0xa4dd0ecb0ef7f117!2sHotel%20Vijayetha!5e0!3m2!1sen!2sin!4v1612280641254!5m2!1sen!2sin"
                      width="100%"
                      height="300px"
                      frameborder="0"
                      style="border: 0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <center>
              <youtube
                :video-id="'khkM_WluOro'"
                :player-width="'100%'"
              ></youtube>
            </center>
            <!-- <v-card max-width="400" class="mx-auto my-12" color="primary" dark>
              <v-card-title class="headline">
                Live stream will start at<br />
                6 AM 15th march. <br />
              </v-card-title>

              <v-card-subtitle>Stay Tuned .. </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  max-width="100%"
                  truncate
                  @click="openGoogleCalendar"
                  color="secondary"
                >
                  Add to Google Calendar
                </v-btn>
              </v-card-actions>
            </v-card> -->
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="text-center">
            <v-card-title class="headline">
              <gallery :gallery="gallery"></gallery>
            </v-card-title>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<style>
.inviteImg {
  max-height: 90%;
  max-width: 90vw;
  margin: 0 auto;
  display: block;
}
.timeline-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(99, 68, 68, 0.3);
}
</style>
<script>
import gallery from "../components/gallery";
import calendar from "../components/calendar";
export default {
  components: {
    gallery,
    calendar,
  },
  data() {
    return {
      tabs: null,
      text: "",
      date: {
        month: "MARCH",
        date: "15",
        year: "2021",
      },
      years: [
        {
          color: "cyan",
          year: "1960",
        },
        {
          color: "green",
          year: "1970",
        },
        {
          color: "pink",
          year: "1980",
        },
        {
          color: "amber",
          year: "1990",
        },
        {
          color: "orange",
          year: "2000",
        },
      ],
      gallery: [
        // {
        //   thumb: "../../img/photos/engagement/thumb/engagement1.jpeg",
        //   src: "../../img/photos/engagement/engagement1.jpeg",
        //   description: "",
        // },
        // {
        //   thumb: "../../img/photos/engagement/thumb/engagement2.jpeg",
        //   src: "../../img/photos/engagement/engagement2.jpeg",
        //   description: "",
        // },
        // {
        //   thumb: "../../img/photos/engagement/thumb/engagement3.jpeg",
        //   src: "../../img/photos/engagement/engagement3.jpeg",
        //   description: "",
        // },
        // {
        //   thumb: "../../img/photos/engagement/thumb/engagement4.jpeg",
        //   src: "../../img/photos/engagement/engagement4.jpeg",
        //   description: "",
        // },
        // {
        //   thumb: "../../img/photos/engagement/thumb/engagement5.jpeg",
        //   src: "../../img/photos/engagement/engagement5.jpeg",
        //   description: "",
        // },
      ],
    };
  },
  methods: {
    openGoogleCalendar() {
      this.$gtag.event("Add Event to Google Calendar", {
        method: "Google Calendar",
      });
      var href =
        "https://www.google.com/calendar/render?action=TEMPLATE&text=Gowri+%26+Murali+Kannan%27s+wedding&details=Gowri+%26+Murali+Kannan+are+getting+hitched.&location=Nagercoil&dates=20210315T003000Z%2F20210315T083000Z";
      window.open(href, "_blank");
    },
  },
};
</script>