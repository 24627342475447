<template>
  <div class="bg_full">
    <IntroFullScreen></IntroFullScreen>
  </div>
</template>
<script>
import IntroFullScreen from "../components/IntroFullScreen";
export default {
  components: {
    IntroFullScreen,
  },
};
</script>