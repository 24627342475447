<template>
  <div>
    <v-card flat>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="text-center" style="width: 100vw">
          <span style="width: 30px; float: left">
            <router-link to="/"> <v-icon>mdi-arrow-left</v-icon> </router-link>
          </span>
          <span> Engagement </span>
        </v-toolbar-title>
        <template v-slot:extension>
          <v-tabs v-model="tabs" centered>
            <v-tab>Photos</v-tab>
            <v-tab>Invitation</v-tab>
            <v-tab>Location</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat>
            <v-card-title class="headline">
              <gallery :gallery="gallery"></gallery>
            </v-card-title>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-img
              src="../../public/img/photos/engagement/engagement_invite.jpg"
              height="90vh"
              contain
              class="grey darken-4"
            ></v-img>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.070537001497!2d77.42908491535418!3d8.195649503738204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f12c8b9c15b1%3A0x1bc9aeb836200872!2zQXJ1bG1pZ3UgU3JpIEthcnV0aHUgVmluYXlhZ2FyIFRlbXBsZSAtIOCuheCusOCvgeCus-CvjeCuruCuv-CuleCvgSDgrrjgr43grrDgr4Ag4K6V4K6w4K-B4K6k4K-N4K6k4K-BIOCuteCuv-CuqOCuvuCur-CuleCusOCvjSDgrpXgr4vgrq_grr_grrLgr40!5e0!3m2!1sen!2sin!4v1611596430247!5m2!1sen!2sin"
              width="100%"
              height="500px"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<style>
.inviteImg {
  max-height: 90%;
  max-width: 90vw;
  margin: 0 auto;
  display: block;
}
</style>
<script>
import gallery from "../components/gallery";
export default {
  components: {
    gallery,
  },
  data() {
    return {
      tabs: null,
      text: "",
      gallery: [
        {
          thumb: "../../img/photos/engagement/thumb/e1.jpg",
          src: "../../img/photos/engagement/e1.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e2.jpg",
          src: "../../img/photos/engagement/e2.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e3.jpg",
          src: "../../img/photos/engagement/e3.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e4.jpg",
          src: "../../img/photos/engagement/e4.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e5.jpg",
          src: "../../img/photos/engagement/e5.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e6.jpg",
          src: "../../img/photos/engagement/e6.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e7.jpg",
          src: "../../img/photos/engagement/e7.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e8.jpg",
          src: "../../img/photos/engagement/e8.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e9.jpeg",
          src: "../../img/photos/engagement/e9.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e10.jpg",
          src: "../../img/photos/engagement/e10.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e11.jpg",
          src: "../../img/photos/engagement/e11.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e12.jpg",
          src: "../../img/photos/engagement/e12.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e13.jpg",
          src: "../../img/photos/engagement/e13.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e14.jpg",
          src: "../../img/photos/engagement/e14.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e15.jpg",
          src: "../../img/photos/engagement/e15.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e16.jpg",
          src: "../../img/photos/engagement/e16.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e17.jpg",
          src: "../../img/photos/engagement/e17.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e18.jpg",
          src: "../../img/photos/engagement/e18.jpg",
          description: "",
        },
        {
          thumb: "../../img/photos/engagement/thumb/e19.jpg",
          src: "../../img/photos/engagement/e19.jpg",
          description: "",
        },
      ],
    };
  },
};
</script>