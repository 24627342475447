<template>
  <div>
    <div class="context">
      <div class="bg_full">
        <div class="title">
          <span class="Parisienne" style="font-size: 2em">
            Gowri & <br /><br />
            Murali Kannan
          </span>
          <div class="GloriaH" style="font-size: 0.8em; margin-top: 10px">
            GOT HITCHED
          </div>
        </div>
      </div>
    </div>

    <div class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="bottomNav">
      <v-row no-gutters>
        <v-col>
          <router-link to="engagement">
            <v-card class="align-center" outlined>
              <br />
              <h4 class="text-center">ENGAGEMENT</h4>
              <calendar class="calendar" :date="engagementDate"></calendar>
              <v-btn x-small class="subtitle" text> View Details</v-btn>
            </v-card>
          </router-link>
        </v-col>
        <v-col>
          <router-link to="wedding">
            <v-card outlined>
              <br />
              <h4 class="text-center">WEDDING</h4>
              <calendar class="calendar" :date="weddingDate"></calendar>
              <v-btn x-small class="subtitle" text> View Details</v-btn>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import calendar from "../components/calendar";

export default {
  components: {
    calendar,
  },
  data() {
    return {
      engagementDate: {
        month: "JANUARY",
        date: "27",
        year: "2021",
      },
      weddingDate: {
        month: "MARCH",
        date: "15",
        year: "2021",
      },
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BenchNine:wght@300&display=swap");
.bg_full {
  /* height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 10; */

  text-align: center;
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.Parisienne {
  font-family: "Parisienne", cursive;
}
.GloriaH {
  font-family: "Gloria Hallelujah", cursive;
}
.BenchNine {
  font-family: "BenchNine", sans-serif;
}
* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Exo", sans-serif;
}
a {
  text-decoration: none !important;
}

.bottomNav {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
}

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  /* background: #ececec; */
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  /* width: 20px;
  height: 20px;
  background: rgba(0, 0, 150, 0.8); */
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li::before,
.circles li::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: rgb(233, 108, 108);
}

.circles li::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.circles li::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}
.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.calendar {
  margin-left: auto !important;
}
.align-center {
  margin: 0 auto !important;
}
</style>