<template>
  <div class="dateCard">
    <div class="dateCard_month primary">{{ date.month }}</div>
    <div class="dateCard_date">{{ date.date }}</div>
    <div class="dateCard_year">{{ date.year }}</div>
  </div>
</template>
<style>
.dateCard {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(99, 68, 68, 0.3);
  background: white;
  text-align: center;
  overflow: hidden;
}
.dateCard_month {
  padding: 5px;
  font-size: 10px;
  color: white;
}
.dateCard_date {
  padding: 10px 0px 10px 0px;
}
.dateCard_year {
  padding: 5px;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 500px) {
  .dateCard {
    margin-left: 20px;
  }
}
</style>
<script>
export default {
  components: {},
  props: ["date"],
};
</script>